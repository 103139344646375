import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import axios from 'axios';
import './index.css';

axios.defaults.baseURL = process.env.VUE_APP_CORETHINGS_API;

const app = createApp(App);
app.use(router);

function callbackRedirect(appState) {
  router.push(appState && appState.targetUrl ? appState.targetUrl : '/');
}

app.config.productionTip = false;
app.mount('#app');
